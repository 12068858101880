<template>
  <div class="container">
    <div class="fenge"></div>
    <div class="top">
      <div class="logo">
        <img src="../assets/tongli.png" alt />
        <p>同力天气查询</p>
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" ref="form" :rules="rules">
          <el-form-item prop="keyword">
            <el-select v-model="formInline.keyword" multiple filterable remote reserve-keyword placeholder="请输入城市" :remote-method="remoteMethod" :loading="loading">
              <el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="time">
            <el-date-picker v-model="formInline.time" type="daterange" align="right" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">获取</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <!-- <div class="table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="date" label="日期" align="center" width="180"></el-table-column>
          <el-table-column prop="name" label="姓名" align="center" width="180"></el-table-column>
          <el-table-column prop="address" label="地址" align="center"></el-table-column>
        </el-table>
      </div>-->
      <div class="otherInfo" v-if="echartsData.city">
        <p class="title">基本信息</p>
        <ul class="ul">
          <li>城市：{{echartsData.city}}</li>
          <li style="margin-left:50px">平均温度：{{echartsData.average}}℃</li>
          <li style="margin-left:50px">天数总计：{{echartsData.days}}天</li>
        </ul>
        <p class="title" style="margin-top:20px">月份平均温度</p>
        <div style="text-align:right;margin-bottom:10px">
          <el-button icon="el-icon-download" type="primary" @click="exportFn">导出</el-button>
        </div>
        <el-table :data="echartsData.monthAverage" border stripe style="width: 100%">
          <el-table-column prop="date" label="日期" align="center" width="180"></el-table-column>
          <el-table-column prop="day" label="统计天数" align="center" width="180"></el-table-column>
          <el-table-column prop="temp" label="平均温度" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.temp}}℃</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- <ul>
          <li v-for="(item,index) in echartsData.monthAverage" :key="index" style="margin-bottom:10px">{{item.label}}：{{item.value}}℃</li>
        </ul>-->
      </div>
      <div class="echart" ref="mychart" :style="{ float: 'left', width: '100%', height: '400px' }"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { debounce } from '../utils'
import * as echarts from 'echarts'
import { getCity, getWeather, exportData } from '@/api'
export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data () {
    return {
      loading: false,
      echartsData: {},
      formInline: {
        keyword: '',
        time: null
      },
      rules: {
        time: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'change'
          }
        ],
        keyword: { required: true, message: '请选择城市', trigger: 'blur' }
      },
      options: [],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    }
  },
  mounted () {
    // this.initEcharts()
  },
  methods: {
    exportFn () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const data = {
            city: this.formInline.keyword[0],
            beginDate: this.formInline.time[0],
            endDate: this.formInline.time[1]
          }
          const res = await exportData(data)
          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '平均温度')
          document.body.appendChild(link)
          link.click()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSubmit () {
      console.log(this.formInline)
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const data = {
            city: this.formInline.keyword[0],
            beginDate: this.formInline.time[0],
            endDate: this.formInline.time[1]
          }
          const res = await getWeather(data)
          this.echartsData = res.data
          this.initEcharts()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    remoteMethod: debounce(function (query) {
      console.log(query, 'query')
      if (query !== '') {
        this.loading = true
        getCity({ keyword: query })
          .then((res) => {
            if (res?.code === 200) {
              this.loading = false
              this.options = res.data.map((item) => {
                return { label: item, value: item }
              })
              console.log(this.options, 'this.options')
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.options = []
      }
    }, 1000),
    initEcharts () {
      const option = {
        title: {
          text: '平均温度'
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => value + '℃'
        },
        legend: {
          show: true,
          x: 'center',
          y: 'top'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.echartsData.date
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} °C'
          }
        },
        series: [
          {
            name: '最高温度',
            type: 'line',
            data: this.echartsData.maxTemp,
            markPoint: {
              label: {
                formatter: '{c}℃'
              },
              data: [
                { type: 'max', name: '最高值' },
                { type: 'min', name: '最低值' }
              ]
            },
            markLine: {
              label: {
                formatter: '{b}:{c}℃'
              },
              data: [{ type: 'average', name: '平均值' }]
            }
          },
          {
            name: '最低温度',
            type: 'line',
            data: this.echartsData.minTemp,
            markPoint: {
              label: {
                formatter: '{c}℃'
              },
              data: [
                { type: 'max', name: '最高值' },
                { type: 'min', name: '最低值' }
              ]
            },
            markLine: {
              label: {
                formatter: '{b}:{c}℃'
              },
              data: [
                { type: 'average', name: '平均值' },
                [
                  {
                    symbol: 'none',
                    x: '90%',
                    yAxis: 'max'
                  },
                  {
                    symbol: 'circle'
                    // label: {
                    //   position: 'start',
                    //   formatter: 'Max'
                    // },
                    // type: 'max',
                    // name: '最高点'
                  }
                ]
              ]
            }
          }
        ]
      }
      const myChart = echarts.init(this.$refs.mychart) // 图标初始化
      myChart.setOption(option) // 渲染页面
      // 随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  background: #fff;
  height: 88px;
  margin: 0 auto;

  .logo {
    display: flex;
    align-items: center;
    height: 100%;
    img {
      height: 80%;
    }
    p {
      font-size: 18px;
      font-weight: 600;
      margin-left: 20px;
    }
  }
}
.fenge {
  width: 100%;
  background: #3388ff;
  display: block;
  height: 46px;
  line-height: 46px;
  margin: 0 auto;
  position: relative;
  z-index: 99999;
}
.content {
  width: 1100px;
  margin: 0 auto;
  clear: both;
  margin-top: 20px;
  padding: 16px;
  position: relative;
  .echart {
    margin-top: 50px;
  }
}
.otherInfo {
  padding-left: 4px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ul {
  display: flex;
  li {
    font-size: 18px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
